import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const EnergyRenovation: FunctionComponent<SvgProps> = (props) => (
  <Svg
    optimizationId="iconFamilyB2CEnergyRenovation"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#1E3C87"
      d="M17.7427 2.73448C17.7427 2.28923 17.3827 1.93399 16.9422 1.93399H15.2796C14.8391 1.93399 14.4791 2.28923 14.4791 2.73448V4.64616C14.4791 4.70988 14.5095 4.76979 14.5609 4.80747L17.4244 6.9063C17.5565 7.00313 17.7427 6.90879 17.7427 6.74499V2.73448Z"
    />
    <path
      fill="#29B9AD"
      d="M21.4837 11.3564C21.3429 11.5485 21.1248 11.6502 20.9038 11.6502C20.7563 11.6502 20.6078 11.6051 20.4799 11.5113L11.7985 5.15184C11.4467 4.89412 10.9684 4.89412 10.6166 5.15184L1.93522 11.5113C1.61533 11.7457 1.16592 11.6765 0.931403 11.3564C0.696857 11.0363 0.766299 10.5869 1.08642 10.3525L10.7831 3.2491C11.0357 3.06393 11.3794 3.06393 11.6319 3.2491L21.3287 10.3525C21.6488 10.5869 21.7182 11.0363 21.4837 11.3564Z"
    />
    <path
      fill="#1E3C87"
      d="M14.7477 7.9015L14.4806 7.70225L11.7975 5.73913C11.4457 5.48171 10.9676 5.48187 10.616 5.73952L3.3152 11.0886C3.05812 11.277 2.90622 11.5766 2.90622 11.8953V21.1151C2.90622 21.6449 3.33188 22.066 3.85718 22.066H18.5606C19.0813 22.066 19.5115 21.6449 19.5115 21.1151V11.8956C19.5115 11.5767 19.3594 11.277 19.1021 11.0887L14.7477 7.9015Z"
    />
    <path
      fill="#29B9AD"
      d="M20.8705 18.7157L23.0703 16.5073C23.147 16.4302 23.1495 16.3064 23.0759 16.2263L22.1713 15.2421L20.8705 13.8436C20.5456 13.4987 20.1232 13.3071 19.6791 13.3071L8.85706 13.3071C8.57642 13.3071 8.34892 13.5346 8.34892 13.8153V18.7504C8.34892 19.031 8.57642 19.2585 8.85707 19.2585H19.6791C20.1232 19.2585 20.5456 19.0669 20.8705 18.7157Z"
    />
    <path
      fill="white"
      d="M15.7912 18.6069L15.5622 17.7353H14.0512L13.8157 18.6069H12.4351L13.9525 13.937H15.629L17.1655 18.6069H15.7912ZM15.3013 16.7046L15.1009 15.9411C15.0543 15.7715 14.997 15.552 14.9292 15.2826C14.8634 15.0133 14.8199 14.8203 14.7987 14.7036C14.7796 14.8118 14.7415 14.9899 14.6842 15.2381C14.6291 15.4862 14.505 15.975 14.312 16.7046H15.3013Z"
    />
  </Svg>
);

EnergyRenovation.displayName = 'Renov';
