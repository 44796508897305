import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const Kitchen: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconFamilyB2CKitchen" viewBox="0 0 24 24" {...props}>
    <path
      fill="#1E3C87"
      d="M10.7 7.5a3.85 3.85 0 11-7.7 0 3.85 3.85 0 017.7 0zM20.428 7.5a3.85 3.85 0 11-7.7 0 3.85 3.85 0 017.7 0z"
    />
    <path
      fill="#1E3C87"
      d="M15.656 6.189a4.189 4.189 0 11-8.378 0 4.189 4.189 0 018.378 0z"
    />
    <path
      fill="#1E3C87"
      d="M15.672 18.358h-8.41c-.6 0-1.086-.487-1.086-1.088V7.16h10.583v10.11c0 .6-.487 1.088-1.087 1.088z"
    />
    <path
      fill="#1D9E9F"
      d="M15.863 21.911H7.07a.896.896 0 01-.896-.895v-1.14c0-.464.377-.841.842-.841h8.9c.465 0 .842.377.842.842v1.139c0 .495-.401.895-.896.895zM9.096 15.61a.348.348 0 01-.348-.349v-2.733a.348.348 0 01.696 0v2.733a.348.348 0 01-.348.348zM11.579 15.61a.348.348 0 01-.349-.349v-2.733a.348.348 0 01.697 0v2.733a.348.348 0 01-.348.348zM13.838 15.61a.348.348 0 01-.348-.349v-2.733a.348.348 0 01.696 0v2.733a.348.348 0 01-.348.348z"
    />
  </Svg>
);

Kitchen.displayName = 'Kitchen';
