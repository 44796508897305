import * as React from 'react';

import { Bathroom } from '@/core/tamagoshi/icons/iconsFamilies/b2c/Bathroom';
import { Carpentry } from '@/core/tamagoshi/icons/iconsFamilies/b2c/Carpentry';
import { Construction } from '@/core/tamagoshi/icons/iconsFamilies/b2c/Construction';
import { Electricity } from '@/core/tamagoshi/icons/iconsFamilies/b2c/Electricity';
import { EnergyRenovation } from '@/core/tamagoshi/icons/iconsFamilies/b2c/EnergyRenovation';
import { Floor } from '@/core/tamagoshi/icons/iconsFamilies/b2c/Floor';
import { Garden } from '@/core/tamagoshi/icons/iconsFamilies/b2c/Garden';
import { Hardware } from '@/core/tamagoshi/icons/iconsFamilies/b2c/Hardware';
import { HomeAndLiving } from '@/core/tamagoshi/icons/iconsFamilies/b2c/HomeAndLiving';
import { HomeAppliance } from '@/core/tamagoshi/icons/iconsFamilies/b2c/HomeAppliance';
import { Kitchen } from '@/core/tamagoshi/icons/iconsFamilies/b2c/Kitchen';
import { Lighting } from '@/core/tamagoshi/icons/iconsFamilies/b2c/Lighting';
import { Painting } from '@/core/tamagoshi/icons/iconsFamilies/b2c/Painting';
import { PetShop } from '@/core/tamagoshi/icons/iconsFamilies/b2c/PetShop';
import { Plumbing } from '@/core/tamagoshi/icons/iconsFamilies/b2c/Plumbing';
import { Tools } from '@/core/tamagoshi/icons/iconsFamilies/b2c/Tools';

interface FamilyIconProps {
  fill?: string;
  id?: number;
  size?: number | string;
  className?: string;
}

const FAMILY_ID_ICONS: Record<number, React.FC> = {
  1: Bathroom,
  116: Kitchen,
  173: Lighting,
  205: Electricity,
  323: Floor,
  463: Tools,
  585: Hardware,
  673: Plumbing,
  796: HomeAppliance,
  823: Garden,
  3943: Construction,
  4203: PetShop,
  4605: HomeAndLiving,
  4899: Painting,
  5200: Carpentry,
  5798: EnergyRenovation,
};

export const FamilyIcon: React.FC<FamilyIconProps> = React.memo(
  ({ id = 4605, ...props }) => {
    const IconComponent = FAMILY_ID_ICONS[id]
      ? FAMILY_ID_ICONS[id]
      : FAMILY_ID_ICONS[4605];

    return (
      <IconComponent
        data-testid={`family-icon-${IconComponent.displayName}`}
        {...props}
      />
    );
  },
);
