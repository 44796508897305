import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const Floor: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconFamilyB2CFloor" viewBox="0 0 24 24" {...props}>
    <path
      fill="#243D80"
      d="M7.706 7.834h-4.99a.509.509 0 0 1-.506-.51v-4.61c0-.51.412-.926.918-.926h4.578a.51.51 0 0 1 .507.511v5.034a.513.513 0 0 1-.507.5zM21.71 7.834h-4.989a.509.509 0 0 1-.506-.51V2.3c0-.276.221-.51.506-.51H21.3c.507 0 .918.415.918.925v4.62a.513.513 0 0 1-.506.5zM14.72 14.89H9.718a.509.509 0 0 1-.506-.511V9.345c0-.277.221-.51.506-.51h4.99c.274 0 .506.223.506.51v5.034a.5.5 0 0 1-.496.51zM7.768 21.945H3.19a.922.922 0 0 1-.918-.926v-4.618c0-.277.221-.511.506-.511h4.99c.274 0 .506.223.506.51v5.034a.515.515 0 0 1-.506.511zM21.36 21.945h-4.577a.509.509 0 0 1-.507-.51V16.4a.51.51 0 0 1 .507-.511h4.99c.274 0 .506.223.506.51v4.62c0 .51-.412.925-.918.925z"
    />
    <path
      fill="#4CAFAD"
      d="M14.72 7.834H9.718a.509.509 0 0 1-.506-.51V2.3c0-.276.221-.51.506-.51h4.99c.274 0 .506.223.506.51v5.034c0 .277-.222.5-.496.5zM7.706 14.89h-4.99a.509.509 0 0 1-.506-.511V9.345c0-.277.222-.51.507-.51h4.99c.274 0 .506.223.506.51v5.034a.516.516 0 0 1-.507.51zM21.71 14.89h-4.989a.509.509 0 0 1-.506-.511V9.345c0-.277.221-.51.506-.51h4.99c.274 0 .506.223.506.51v5.034a.515.515 0 0 1-.506.51zM14.77 21.945H9.78a.509.509 0 0 1-.506-.51V16.4a.51.51 0 0 1 .507-.511h4.989c.274 0 .506.223.506.51v5.034a.501.501 0 0 1-.506.511z"
    />
  </Svg>
);
Floor.displayName = 'Painting';
