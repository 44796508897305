import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const Electricity: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconFamilyB2CElectricity" viewBox="0 0 24 24" {...props}>
    <path
      fill="#1D9E9F"
      d="M8.143 7.663h-.248a.687.687 0 01-.685-.686V2.725c0-.376.308-.685.685-.685h.248c.377 0 .685.309.685.685v4.252a.687.687 0 01-.685.686zM15.585 7.622h-.247a.687.687 0 01-.685-.685V2.685c0-.377.308-.685.685-.685h.247c.377 0 .685.308.685.685v4.252a.687.687 0 01-.685.685z"
    />
    <path
      fill="#1E3C87"
      d="M8.76 19a.64.64 0 01-.654-.64v-1.689s.107-.891-1.426-1.818-2.175-2.068-2.175-2.068-.57-2.032-.499-4.67c0 0 .107-1.034 1.284-1.07 1.176-.035 13.012 0 13.012 0s1.142-.06 1.179 1.09v3.102s-.182 2.923-2.733 3.672c0 0-1.534.482-1.499 2.73l.009.593a.64.64 0 01-.64.65h-.396a.31.31 0 00-.31.31v2.29c0 .281-.228.509-.509.509h-3.145a.508.508 0 01-.508-.503l-.026-2.195a.31.31 0 00-.317-.307l-.646.015z"
    />
    <path
      fill="#1D9E9F"
      d="M13.472 11.432l-1.437.227.97-1.699c.061-.103-.021-.234-.145-.227l-1.905.165a.158.158 0 00-.131.104l-.984 3.074a.15.15 0 00.172.193l.998-.186-.86 2.992c-.048.166.172.269.268.131l3.199-4.533c.082-.11-.014-.261-.145-.24z"
    />
  </Svg>
);

Electricity.displayName = 'Electricity';
