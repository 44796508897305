import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const PetShop: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconFamilyB2CPetShop" viewBox="0 0 24 24" {...props}>
    <path
      fill="#1E3C87"
      d="M11.794 11.736c1.477.85 2.998 7.292 1.932 9.134-.569.982-2 1.469-3.477.618-.739-.425-1.933-2.15-2.548-2.505s-2.813-.422-3.674-.918c-.861-.497-1.279-2.046-.64-3.152 1.258-1.687 6.58-4.19 8.407-3.177z"
    />
    <path
      fill="#1D9E9F"
      d="M9.41 4.862c-.524 1.84-.064 3.55 1.025 3.823 1.09.272 2.394-.993 2.918-2.832.524-1.84.063-3.557-1.026-3.83A1.54 1.54 0 0012.06 2c-1.216 0-2.174 1.468-2.65 2.862zM15.239 6.69c-1.335 1.373-1.781 3.138-1 3.942.782.805 2.496.349 3.83-1.023 1.335-1.373 1.788-3.138 1.007-3.943a1.373 1.373 0 00-1.007-.408c-.983 0-2.127.835-2.83 1.432zM3.368 8.146c-.427 1.768.076 3.455 1.119 3.763 1.043.308 2.231-.878 2.658-2.646.427-1.768-.07-3.448-1.113-3.756-.11-.02-.217-.03-.321-.03-1.196 0-1.952 1.29-2.343 2.67zM16.924 12.833c-1.56.897-2.39 2.376-1.852 3.304.537.928 2.238.956 3.797.06 1.56-.897 2.389-2.377 1.852-3.304-.315-.513-.871-.7-1.49-.7-.796 0-1.695.312-2.307.64z"
    />
  </Svg>
);

PetShop.displayName = 'PetShop';
