import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const Lighting: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconFamilyB2CLighting" viewBox="0 0 24 24" {...props}>
    <path
      fill="#1E3C87"
      d="M16.896 19.322h-8.92c-.818 0-1.482.663-1.482 1.482v1.147h11.884v-1.147c0-.819-.664-1.482-1.482-1.482z"
    />
    <path
      fill="#1D9E9F"
      d="M11.436 19.322h2v-8.334h-2v8.334zM15.372 14.47h.559v-3.482h-.56v3.482z"
    />
    <path
      fill="#1E3C87"
      d="M16.596 14.935a.945.945 0 11-1.89 0 .945.945 0 011.89 0zM7.004 11.966h10.865c.67 0 1.15-.564.963-1.127l-2.743-8.207C15.963 2.257 15.57 2 15.126 2H9.82c-.443 0-.833.254-.961.626l-2.816 8.207c-.194.564.289 1.133.961 1.133z"
    />
  </Svg>
);

Lighting.displayName = 'Lighting';
