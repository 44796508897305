import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const Hardware: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconFamilyB2CHardware" viewBox="0 0 24 24" {...props}>
    <path
      fill="#1D9E9F"
      d="M3.947 10.751l-.942-1.185a.745.745 0 01.117-1.058l8.529-6.815a.745.745 0 011.058.116l.942 1.186a.745.745 0 01-.117 1.058l-8.529 6.815a.747.747 0 01-1.058-.117z"
    />
    <path
      fill="#1D3E83"
      d="M15.683 19.608l4.698 2.773c.413.243.9-.159.73-.614l-1.884-5.217a2.661 2.661 0 00-.412-.762l-.927-1.207-3.084 4.17a.36.36 0 01-.016.02l.09.118c.223.296.487.54.805.72zm-1.409-1.504l3.1-4.192-.842-1.098-3.101 4.194.843 1.096zm.925-7.026l.818 1.065-3.1 4.197-.821-1.065 3.103-4.197zm-3.617 3.529l3.103-4.198-.857-1.116-3.104 4.2.857 1.114zm.917-7.045l.82 1.067-3.11 4.196-.816-1.061 3.106-4.202zm-3.62 3.534l3.107-4.203-.864-1.126L7.196 8.91l1.683 2.186z"
    />
  </Svg>
);

Hardware.displayName = 'Hardware';
